<template>
  <div class="mb-2">
    <template v-if="getLogs.length === 0 || isLoading === true">
      <div
        class="
          flex
          items-center
          justify-center
          py-5
          bg-white
          rounded-md
          shadow
          h-96
        "
      >
        <div
          class="text-xl font-medium"
          v-text="isLoading ? 'Loading..' : 'No logs available'"
        />
        <AppButton :is-loading="isLoading" variant="transparent" text="" />
      </div>
    </template>

    <template v-else>
      <div class="full-mode">
        <section class="fst-wrapper">
          <table class="fst">
            <thead class="fst-head">
              <tr class="fst-head-row">
                <th
                  v-for="header in getHeaders"
                  :key="header.label"
                  :width="header.width"
                  class="fst-head-row-item event-col-header"
                  :class="{ 'ta-center-imp': header.center }"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <template v-for="(log, logIndex) in getLogs">
              <TimelineLogItem
                :key="`log-item-${logIndex}`"
                :variant="getLogItemVariant(log)"
                :time="getLogTime(log)"
                :date="getLogDate(log)"
                :time-ago="getLogTimeAgo(log)"
                :oto-icon="log.request_title"
                :timelineType="timelineType"
                :response-code="getLogResponseCode(log)"
                :log-request-trace="getLogRequestTrace(log)"
                :has-show-log="getShowlogStatus(log)"
                :request-log-id="log.id ? log.id : ''"
              >
                <template #visible>
                  <TimelineLogLayerVisible :html-title="getHtmlTitle(log)" />
                </template>
              </TimelineLogItem>
            </template>
          </table>
        </section>
      </div>
      <div class="responsive-mode">
        <section class="fst-wrapper">
          <table class="fst">
            <thead class="fst-head">
              <tr class="fst-head-row">
                <th
                  v-for="header in getResponsiveHeaders"
                  :key="header.label"
                  :width="header.width"
                  class="fst-head-row-item event-col-header"
                  :class="{ 'ta-center-imp': header.center }"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <template v-for="(log, logIndex) in getLogs">
              <TimelineLogItem
                :key="`log-item-${logIndex}`"
                :variant="getLogItemVariant(log)"
                :time="getLogTime(log)"
                :date="getLogDate(log)"
                :time-ago="getLogTimeAgo(log)"
                :oto-icon="log.request_title"
                :timelineType="timelineType"
                :response-code="getLogResponseCode(log)"
                :log-request-trace="getLogRequestTrace(log)"
                :has-show-log="getShowlogStatus(log)"
                :request-log-id="log.id ? log.id : ''"
              >
                <template #visible>
                  <TimelineLogLayerVisible :html-title="getHtmlTitle(log)" />
                </template>
              </TimelineLogItem>
            </template>
          </table>
        </section>
      </div>
    </template>

    <GoogleMapModalAlt
      :marker-is-updatable="false"
      :variant="`vehicle`"
      :title="`Location On The Map`"
      :subtitle="''"
    />
  </div>
</template>

<script>
import { getTimeAgo } from '@/utils/datetime'

import TimelineLogItem from '@/components/timeline-new-final/TimelineLogItem.vue'
import TimelineLogLayerVisible from './TimelineLogLayerVisible.vue'

// const HeaderGroups = {
//   generic: [
//     { label: 'Time', width: '13%' },
//     { label: 'Event Type', width: '13%' },
//     { label: 'Event Details', width: '74%' },
//   ],
//   rider: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   riderpayment: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   trip: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   vehicle: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   smslogs: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   rental: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//   ],
//   report: [
//     { label: 'Time', width: '10%' },
//     { label: 'Event Type', width: '10%' },
//     { label: 'Event Details', width: '46%' },
//     { label: 'Response', width: '8%', center: true },
//     { label: 'Points', width: '8%', center: true },
//     { label: 'Remarks', width: '8%', center: true },
//     { label: 'Actor', width: '10%', center: true },
//   ],
// }
const ResponsiveHeaderGroups = [
  { text: '', width: '10%' },
  { label: 'Time', width: '13%' },
  { label: 'Event Details', width: '74%' },
]
export default {
  name: 'TimelineGroup',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    logs: {
      type: Array,
      default: () => [],
    },
    infoColumnPreset: {
      type: String,
      default: 'default',
    },
    timelineType: {
      type: String,
      required: false,
      default: 'generic',
    },
    isPointsShow: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    TimelineLogItem,
    TimelineLogLayerVisible,
    GoogleMapModalAlt: () =>
      import('@/components/modals/GoogleMapModalAlt.vue'),
  },
  data() {
    return {
      HeaderGroups: {
        generic: [
          {
            label: this.$t('components.timelineGroups.generic.time'),
            width: '13%',
          },
          {
            label: this.$t('components.timelineGroups.generic.eventType'),
            width: '13%',
          },
          {
            label: this.$t('components.timelineGroups.generic.eventDetails'),
            width: '74%',
          },
        ],
        rider: [
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.logs.timeline.columns.time'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.logs.timeline.columns.eventType'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.logs.timeline.columns.eventDetails'
            ),
            width: '46%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.logs.timeline.columns.response'
            ),
            width: '8%',
            center: true,
          },
        ],
        riderpayment: [
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.columns.time'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.columns.eventType'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.columns.eventDetails'
            ),
            width: '46%',
          },
          {
            label: this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.columns.response'
            ),
            width: '8%',
            center: true,
          },
        ],
        trip: [
          {
            label: this.$t(
              'components.tripManagement.details.tripTimeline.columns.time'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.tripManagement.details.tripTimeline.columns.eventType'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.tripManagement.details.tripTimeline.columns.eventDetails'
            ),
            width: '46%',
          },
          {
            label: this.$t(
              'components.tripManagement.details.tripTimeline.columns.response'
            ),
            width: '8%',
            center: true,
          },
        ],
        vehicle: [
          {
            label: this.$t('components.timelineGroups.generic.time'),
            width: '10%',
          },
          {
            label: this.$t('components.timelineGroups.generic.eventType'),
            width: '10%',
          },
          {
            label: this.$t('components.timelineGroups.generic.eventDetails'),
            width: '46%',
          },
          {
            label: this.$t('components.timelineGroups.generic.response'),
            width: '8%',
            center: true,
          },
        ],
        smslogs: [
          {
            label: this.$t(
              'components.logsManagement.signInLogs.table.columns.time'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.logsManagement.signInLogs.table.columns.eventDetails'
            ),
            width: '46%',
          },
          {
            label: this.$t(
              'components.logsManagement.signInLogs.table.columns.response'
            ),
            width: '8%',
            center: true,
          },
        ],
        rental: [
          {
            label: this.$t(
              'components.vehicleRentalsManagement.details.tripTimeline.columns.time'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.vehicleRentalsManagement.details.tripTimeline.columns.eventType'
            ),
            width: '10%',
          },
          {
            label: this.$t(
              'components.vehicleRentalsManagement.details.tripTimeline.columns.eventDetails'
            ),
            width: '46%',
          },
          {
            label: this.$t(
              'components.vehicleRentalsManagement.details.tripTimeline.columns.response'
            ),
            width: '8%',
            center: true,
          },
        ],
        report: [
          { label: 'Time', width: '10%' },
          { label: 'Event Type', width: '10%' },
          { label: 'Event Details', width: '46%' },
          { label: 'Response', width: '8%', center: true },
          { label: 'Points', width: '8%', center: true },
          { label: 'Remarks', width: '8%', center: true },
          { label: 'Actor', width: '10%', center: true },
        ],
      },
    }
  },
  computed: {
    getLogs() {
      return this.logs
    },
    getHeaders() {
      if (!this.isPointsShow) {
        const tHeaders = this.HeaderGroups[this.timelineType]
        return tHeaders.filter((item) => item.label !== 'Points')
      }
      return this.HeaderGroups[this.timelineType]
    },
    getResponsiveHeaders() {
      return ResponsiveHeaderGroups
    },
  },

  methods: {
    toJSONSafe(rawData) {
      try {
        return JSON.parse(rawData)
      } catch {
        return rawData
      }
    },
    getShowlogStatus(log) {
      if (log.response) {
        return true
      } else {
        return false
      }
    },
    // log item getters
    getHtmlTitle(log) {
      const actionTypeText = log.request_title.split('_').join(' ') || '--'

      return `<b class="font-bold">${actionTypeText}</b> : <span class="font-normal">${log.description ||
        '--'}</span>`
    },

    getLogTime(log) {
      return this.$UTCAwareTime(log?.created_at, {
        format: 'hh:mm:ss a',
      })
    },
    getLogDate(log) {
      return log && log.created_at
        ? this.$UTCAwareTime(log?.created_at, {
            format: 'DD MMM, YYYY',
          })
        : '--'
    },
    getLogTimeAgo(log) {
      return log && log.created_at ? getTimeAgo(log.created_at) : '--'
    },
    getLogRequestTrace(log) {
      const trace = log
      if (!trace) return ''
      const reqLogs = {
        id: trace?.id,
        ip: trace?.ip,
        user_agent: trace?.user_agent,
        host: trace?.host,
        method: trace?.method,
        path: trace?.path,
        request_body: this.toJSONSafe(trace?.request_data),
      }

      let response = trace?.response
      const resLogs = {
        language: trace?.language,
        response_code: trace?.response_code,
        response_time: trace?.exec_time,
        response_body: this.toJSONSafe(response),
      }
      return { requestLogs: reqLogs, responseLogs: resLogs }
    },

    getLogResponseCode(log) {
      return log?.response_code || 0
    },

    getLogItemVariant(log) {
      const code = this.getLogResponseCode(log)
      if (code >= 400 && code <= 500) return 'danger'
      // if (code >= 200 && code < 300) return 'light'
      return 'light'
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-section {
  min-height: 4rem;
  @apply grid items-center w-full px-6 py-1 mb-2 bg-white rounded-md shadow;
  @apply grid-cols-1 md:grid-cols-3;
}
.timeline-title {
  display: grid;
  // border: 1px solid red;
  grid-template-columns: 175px 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: 175px 1fr 375px;
  }
}

.fst .fst-head,
.fst .fst-head .fst-head-row {
  border-color: transparent !important;
  box-shadow: none !important;
}

.event-col-header {
  font-weight: 800 !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.fst-wrapper {
  @apply p-4;
}

.ta-center-imp {
  text-align: center !important;
}

.test {
  border: 1px dotted red;
}
</style>

<style lang="scss" scoped>
@import '@/components/fs-table/$fs-table.scss';
</style>

<template>
  <div class="bg-white rounded">
    <section class="w-full px-6 py-3 bg-white rounded-md shadow text-center">
      <span
        class="font-extrabold text-2xl "
        v-text="
          $t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.headline'
          )
        "
      />
    </section>
    <div class="flex py-5 px-3 justify-end">
      <date-range-picker
        :time-filter-enabled="true"
        @apply-date="onApplyFilterDateRange"
        @cancel-date="onApplyFilterDateRange"
      />
    </div>

    <template>
      <FSTable
        :fst-id="fstId"
        :endpoint="getEndpoint"
        :isExportEnabled="false"
        :searchEnabled="false"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>
        <template #default="{ data }">
          <TimelineGroup
            v-if="data.length > 0"
            :logs="data"
            timeline-type="riderpayment"
          />
        </template>
      </FSTable>
    </template>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import {
  FSTable,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
// import TitlePlus from '@/components/ui/TitlePlus'
export default {
  name: 'RiderPaymentLogs',

  components: {
    FSTable,
    FSTableFilter,
    TimelineGroup,
    // TitlePlus,
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.rider.paymentLogs(this.reqId)
    },
  },
  data() {
    return {
      fstId: 'rider-payment-logs',
      filterOptions: [
        {
          key: 'request_title',
          type: 'checkbox',
          input: [
            { text: 'Invoice Refund', value: 'INVOICE_REFUND' },
            { text: 'Balance Adjust', value: 'BALANCE_ADJUST' },
            {
              text: 'Positive Balance Adjust',
              value: 'POSITIVE_BALANCE_ADJUST',
            },
            {
              text: 'Negative Balance Adjust',
              value: 'NEGATIVE_BALANCE_ADJUST',
            },
            {
              text: 'Default Payment Source Set',
              value: 'DEFAULT_PAYMENT_SOURCE_SET',
            },
            { text: 'Default Card Set', value: 'DEFAULT_CARD_SET' },
            { text: 'Card Add', value: 'CARD_ADD' },
            { text: 'Redirection', value: 'REDIRECTION' },
            { text: 'Redirect Topup', value: 'REDIRECT_TOPUP' },
            { text: 'Direct Topup', value: 'DIRECT_TOPUP' },
            { text: 'Card Delete', value: 'CARD_DELETE' },
            {
              text: 'Redirect Pass Subscribe',
              value: 'REDIRECT_PASS_SUBSCRIBE',
            },
            { text: 'Direct Pass Subscribe', value: 'DIRECT_PASS_SUBSCRIBE' },

            {
              text: 'Trigger Payment By System',
              value: 'TRIGGER_PAYMENT_BY_SYSTEM',
            },
          ],
          title: 'Category',
        },
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Success', value: 'succeeded' },
            { text: 'Failed', value: 'failed' },
          ],
          title: 'Status',
        },
      ],
    }
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>

<style lang="scss" scoped></style>

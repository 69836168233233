var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded"},[_c('section',{staticClass:"w-full px-6 py-3 bg-white rounded-md shadow text-center"},[_c('span',{staticClass:"font-extrabold text-2xl ",domProps:{"textContent":_vm._s(
        _vm.$t(
          'components.riderDetailsManagement.diveIntoTab.paymentMethod.timeline.headline'
        )
      )}})]),_c('div',{staticClass:"flex py-5 px-3 justify-end"},[_c('date-range-picker',{attrs:{"time-filter-enabled":true},on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1),[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"endpoint":_vm.getEndpoint,"isExportEnabled":false,"searchEnabled":false},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
      var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
      var data = ref.data;
return [(data.length > 0)?_c('TimelineGroup',{attrs:{"logs":data,"timeline-type":"riderpayment"}}):_vm._e()]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }